













































































































































































































































































































































































































































































































































































































































.guest-invite-card {
  .js-badger-accordion-header {
    .badger-accordion-toggle {
      @apply bg-white border border-gray-400 border-solid text-gray-600;
    }
    .ba-hidden {
      @apply bg-teal-500;
    }
  }
}

.group {
  .formulate-input-label {
    @apply text-teal-500;
  }
}

@media only screen and (max-width: 600px) {
  .invite-buttons {
    @apply flex-col;
  }
}
