











































































































































































































































































































































































































































































































































































































































































































































































































































































































































.form-wrap {
  display: flex;
  flex-wrap: wrap;
  .formulate-input {
    flex: 1 0 33%;
    margin: 5px;
  }
}
html {
  overflow-y: auto;
}

.stepTitle {
  width: 40% !important;
}
.terms-container {
  position: relative;
  height: 500px;
  h2 {
    line-height: 3;
  }
}
.center-element {
  position: absolute;
  top: 50%;
  width: 40%;
  margin: 0;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

#signature {
  margin: auto;
  margin-top: 25px;
  margin-bottom: 15px;
  background-image: linear-gradient(white, white), radial-gradient(circle at top left, #4bc5e8, #9f6274);
  background-clip: content-box, border-box;
  background-origin: border-box;
  border: double 3px transparent;
  border-radius: 5px;
}

// @media only screen and (max-width: 600px) {
//   .wizard-progress-bar,
//   .wizard-nav.wizard-nav-pills {
//     display: none !important;
//   }
// }
